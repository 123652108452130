(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/base-addons/assets/javascripts/base-addons.js') >= 0) return;  svs.modules.push('/components/tipsen/base-addons/assets/javascripts/base-addons.js');
"use strict";


const {
  ReactPaper,
  ReactIcon
} = svs.ui;
const {
  DialogContextual
} = svs.components.tipsen;
const {
  useBranding
} = svs.components.tipsen.hooks;
const BaseAddons = _ref => {
  let {
    children,
    className,
    description,
    dialog,
    icon,
    id,
    title
  } = _ref;
  const classNames = ["tipsen-addition ".concat(id)].concat(className !== null && className !== void 0 ? className : []);
  const branding = useBranding();
  const iconClassNames = ['tipsen-addition-icon'];
  if (branding.length > 1) {
    iconClassNames.push(branding[branding.length - 1]);
  }
  return React.createElement(ReactPaper, {
    branding: "sport",
    className: classNames.join(' ')
  }, React.createElement("article", {
    "aria-labelledby": "tipsen-addition-title-".concat(id),
    className: "tipsen-addition-grid"
  }, React.createElement("div", {
    className: "tipsen-addition-header"
  }, React.createElement(ReactIcon, {
    className: iconClassNames.join(' '),
    color: "fc-white",
    icon: icon
  }), React.createElement("h3", {
    className: "margin-right tipsen-addition-title",
    id: "tipsen-addition-title-".concat(id)
  }, title), dialog && React.createElement(DialogContextual, null, dialog)), description && React.createElement("p", {
    className: "tipsen-addition-desc"
  }, description), children));
};
setGlobal('svs.components.tipsen.baseAddons.BaseAddons', BaseAddons);

 })(window);